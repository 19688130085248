.NFTFilters.empty {
  margin-top: 12px;
}

.NFTFilters .topbar {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.NFTFilters .topbar .topbar-filter {
  margin-left: 20px;
}

.NFTFilters .topbar > .full-width,
.NFTFilters .topbar > .TextFilter {
  flex: 1 0 auto;
  margin-right: 30px;
}

.NFTFilters .topbar > .TextFilter .text-input input {
  padding-top: 0;
  font-size: 15px;
  background-position-y: 4px;
}

.NFTFilters .topbar .ui.dropdown {
  display: flex;
  line-height: 1;
}

.NFTFilters .Filter .ui.sub.header.name {
  margin-bottom: 12px !important;
  font-weight: 600;
}

.NFTFilters .filters {
  padding: 16px;
  background-color: var(--card);
  border-radius: 16px;
  margin-bottom: 32px;
  user-select: none;
  outline: none;
  padding-bottom: 8px;
  margin-top: -8px;
}

.AssetBrowse .filters .Filter + .Filter {
  margin-top: 14px;
}

.NFTFilters .filters .dcl.row {
  align-items: flex-start;
}

.NFTFilters .filters .dcl.row + .dcl.row {
  margin-top: 12px;
}

.NFTFilters .filters .dcl.row .Filter {
  flex: 1 0 auto;
}

.NFTFilters .filters .dcl.row .Filter + .Filter {
  margin-top: 0px;
  flex: none;
  width: 50%;
  margin-left: 20px;
}

.NFTFilters .ui.toggle.checkbox input.hidden:focus + label,
.NFTFilters .ui.toggle.checkbox.checked input.hidden + label,
.NFTFilters .ui.toggle.checkbox input.hidden + label,
.NFTFilters .ui.toggle.checkbox.checked input.hidden:focus + label {
  font-size: 12px;
  margin-right: 0px;
  padding-left: 33px;
  line-height: 12px;
  color: var(--primary) !important;
}

.NFTFilters .ui.toggle.checkbox input.hidden + label:before {
  width: 28px;
  height: 12px;
}

.NFTFilters .ui.toggle.checkbox input.hidden + label:after {
  background: var(--primary);
  width: 12px;
  height: 12px;
}

.NFTFilters .ui.toggle.checkbox label,
.NFTFilters .ui.dropdown > .text {
  text-transform: uppercase;
}

.NFTFilters .filters .ui.dropdown .visible.menu {
  max-height: 500px;
  overflow: auto;
}

.NFTFilters .open-filters-wrapper {
  display: flex;
  position: relative;
  cursor: pointer;
}

.NFTFilters .open-filters-wrapper .label {
  color: var(--primary);
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 500;
}

.NFTFilters .open-filters {
  width: 20px;
  height: 20px;
  background-image: url(../../../images/filters.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.NFTFilters .open-filters.active {
  background-image: url(../../../images/filters-active.svg);
  background-size: 20px;
}

.NFTFilters .Chip.grid {
  border-radius: 4px 0 0 4px;
}

.NFTFilters .Chip.atlas {
  border-radius: 0 4px 4px 0;
}

/*------------------
  FiltersModal
 */

.FiltersModal .NFTSidebar ul.menu li:hover,
.FiltersModal .NFTSidebar ul.menu li.active {
  background-color: var(--secondary-on-modal);
}

.FiltersModal .filter-row {
  margin-bottom: 20px;
}

.FiltersModal .filter-row .ui.sub.header {
  margin-bottom: 12px;
}

.FiltersModal .NFTSidebar {
  width: 100%;
}

.FiltersModal .NFTSidebar ul.menu {
  width: calc(100% + 16px);
}

.FiltersModal .NFTSidebar ul.menu li {
  width: calc(100% + 16px);
}

.FiltersModal .NFTSidebar ul.menu li.sub {
  width: calc(100% + 0px);
}

.FiltersModal.ui.modal .dcl.close {
  position: absolute;
  right: 24px !important;
  top: 24px !important;
}

.FiltersModal .apply-filters {
  width: 100%;
  margin-top: 48px;
}

.FiltersModal .ui.dropdown {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  color: var(--secondary-text);
}

.FiltersModal .ui.dropdown .visible.menu {
  max-height: 500px;
  overflow: auto;
}

.FiltersModal .ui.dropdown .text {
  flex: 1 0 auto;
  color: var(--text);
}

.FiltersModal .ui.dropdown .dropdown.icon {
  color: var(--text);
}

@media (max-width: 768px) {
  .NFTFilters .topbar {
    align-items: normal;
  }

  .FiltersModal .dcl.row {
    align-items: flex-start;
    flex-direction: column;
    justify-content: stretch;
  }
}
