.WearableTags {
  display: flex;
}

.WearableTags .rarity {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 3px 6px;
  border-radius: 4px;
}

.WearableTags .icon {
  margin-left: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 24px;
  height: 24px;
  background-color: #37333d;
  border-radius: 4px;
}

.WearableTags .icon.body_shape {
  background-image: url(../../../images/wearables/BodyShapeIcon.svg);
}

.WearableTags .icon.earring {
  background-image: url(../../../images/wearables/EarringsIcon.svg);
}

.WearableTags .icon.eyebrows {
  background-image: url(../../../images/wearables/EyebrowIcon.svg);
}

.WearableTags .icon.eyes {
  background-image: url(../../../images/wearables/EyesIcon.svg);
}

.WearableTags .icon.eyewear {
  background-image: url(../../../images/wearables/EyewearIcon.svg);
}

.WearableTags .icon.facial_hair {
  background-image: url(../../../images/wearables/FacilHairIcon.svg);
}

.WearableTags .icon.feet {
  background-image: url(../../../images/wearables/FeetIcon.svg);
}

.WearableTags .icon.hair {
  background-image: url(../../../images/wearables/HairIcon.svg);
}

.WearableTags .icon.hat {
  background-image: url(../../../images/wearables/HatIcon.svg);
}

.WearableTags .icon.helmet {
  background-image: url(../../../images/wearables/HelmetIcon.svg);
}

.WearableTags .icon.lower_body {
  background-image: url(../../../images/wearables/LowerBodyIcon.svg);
}

.WearableTags .icon.mask {
  background-image: url(../../../images/wearables/MaskIcon.svg);
}

.WearableTags .icon.mouth {
  background-image: url(../../../images/wearables/MouthIcon.svg);
}

.WearableTags .icon.tiara {
  background-image: url(../../../images/wearables/TiaraIcon.svg);
}

.WearableTags .icon.top_head {
  background-image: url(../../../images/wearables/TopHeadIcon.svg);
}

.WearableTags .icon.upper_body {
  background-image: url(../../../images/wearables/UpperBodyIcon.svg);
}

.WearableTags .icon.BaseMale {
  background-image: url(../../../images/wearables/MaleIcon.svg);
  background-size: 14px 14px;
}

.WearableTags .icon.BaseFemale {
  background-image: url(../../../images/wearables/FemaleIcon.svg);
  background-size: 14px 14px;
}

.WearableTags .icon.Unisex {
  background-image: url(../../../images/wearables/UnisexIcon.svg);
  background-size: 14px 14px;
}

.WearableTags .icon.gun {
  background-image: url(../../../images/wearables/GunIcon.svg);
}

.WearableTags .icon.pipe {
  background-image: url(../../../images/wearables/PipeIcon.svg);
}

.WearableTags .icon.bong {
  background-image: url(../../../images/wearables/BongIcon.svg);
}

.WearableTags .icon.cigar {
  background-image: url(../../../images/wearables/CigarIcon.svg);
}

.WearableTags .icon.joint {
  background-image: url(../../../images/wearables/JointIcon.svg);
}

.WearableTags .icon.beer {
  background-image: url(../../../images/wearables/BeerIcon.svg);
}

.WearableTags .icon.balloons {
  background-image: url(../../../images/wearables/PipeIcon.svg);
}

.WearableTags .icon.guitar {
  background-image: url(../../../images/wearables/GuitarIcon.svg);
}

.WearableTags .icon.gaming {
  background-image: url(../../../images/wearables/GamingIcon.svg);
}

.WearableTags .icon.headphones {
  background-image: url(../../../images/wearables/HeadphonesIcon.svg);
}

.WearableTags .icon.soccer {
  background-image: url(../../../images/wearables/SoccerIcon.svg);
}

.WearableTags .icon.basketball {
  background-image: url(../../../images/wearables/BasketballIcon.svg);
}

.WearableTags .icon.golf {
  background-image: url(../../../images/wearables/GolfIcon.svg);
}

.WearableTags .icon.snowboard {
  background-image: url(../../../images/wearables/SnowboardIcon.svg);
}

.WearableTags .icon.skis {
  background-image: url(../../../images/wearables/SkisIcon.svg);
}

.WearableTags .icon.skateboard {
  background-image: url(../../../images/wearables/SkateboardIcon.svg);
}

.WearableTags .icon.rollerskates {
  background-image: url(../../../images/wearables/RollerSkatesIcon.svg);
}

.WearableTags .icon.weights {
  background-image: url(../../../images/wearables/WeightsIcon.svg);
}

.WearableTags .icon.hotdog {
  background-image: url(../../../images/wearables/HotdogIcon.svg);
}

.WearableTags .icon.pizza {
  background-image: url(../../../images/wearables/PizzaIcon.svg);
}

.WearableTags .icon.ice_cream {
  background-image: url(../../../images/wearables/IceCreamIcon.svg);
}

.WearableTags .icon.burger {
  background-image: url(../../../images/wearables/BurgerIcon.svg);
}

.WearableTags .icon.taco {
  background-image: url(../../../images/wearables/TacoIcon.svg);
}

.WearableTags .icon.dog {
  background-image: url(../../../images/wearables/DogIcon.svg);
}

.WearableTags .icon.cat {
  background-image: url(../../../images/wearables/CatIcon.svg);
}

.WearableTags .icon.panda {
  background-image: url(../../../images/wearables/PandaIcon.svg);
}

.WearableTags .icon.dragon {
  background-image: url(../../../images/wearables/DragonIcon.svg);
}

.WearableTags .icon.shark {
  background-image: url(../../../images/wearables/SharkIcon.svg);
}

.WearableTags .icon.fish {
  background-image: url(../../../images/wearables/FishIcon.svg);
}

.WearableTags .icon.dolphin {
  background-image: url(../../../images/wearables/DolphinIcon.svg);
}

.WearableTags .icon.bird {
  background-image: url(../../../images/wearables/BirdIcon.svg);
}


@media (min-width: 768px) and (max-width: 992px) {
  .WearableTags .icon {
    display: none;
  }
}
