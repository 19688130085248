.rarity {
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px;
  padding: 7px 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: default;
  margin-left: 16px;
  margin-top: 6px;
  cursor: pointer;
}
