.WearableHighlights .left > div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.WearableHighlights .left > .BaseMale {
  background-image: url(../../../images/wearables/MaleIcon.svg);
  background-size: 24px 24px;
}

.WearableHighlights .left > .BaseFemale {
  background-image: url(../../../images/wearables/FemaleIcon.svg);
  background-size: 24px 24px;
}

.WearableHighlights .left > .Unisex {
  background-image: url(../../../images/wearables/UnisexIcon.svg);
  background-size: 24px 24px;
}

.WearableHighlights .body_shape {
  background-image: url(../../../images/wearables/BodyShapeIcon.svg);
}

.WearableHighlights .earring {
  background-image: url(../../../images/wearables/EarringsIcon.svg);
}

.WearableHighlights .eyebrows {
  background-image: url(../../../images/wearables/EyebrowIcon.svg);
}

.WearableHighlights .eyes {
  background-image: url(../../../images/wearables/EyesIcon.svg);
}

.WearableHighlights .eyewear {
  background-image: url(../../../images/wearables/EyewearIcon.svg);
}

.WearableHighlights .facial_hair {
  background-image: url(../../../images/wearables/FacilHairIcon.svg);
}

.WearableHighlights .feet {
  background-image: url(../../../images/wearables/FeetIcon.svg);
}

.WearableHighlights .hair {
  background-image: url(../../../images/wearables/HairIcon.svg);
}

.WearableHighlights .hat {
  background-image: url(../../../images/wearables/HatIcon.svg);
}

.WearableHighlights .helmet {
  background-image: url(../../../images/wearables/HelmetIcon.svg);
}

.WearableHighlights .lower_body {
  background-image: url(../../../images/wearables/LowerBodyIcon.svg);
}

.WearableHighlights .mask {
  background-image: url(../../../images/wearables/MaskIcon.svg);
}

.WearableHighlights .mouth {
  background-image: url(../../../images/wearables/MouthIcon.svg);
}

.WearableHighlights .tiara {
  background-image: url(../../../images/wearables/TiaraIcon.svg);
}

.WearableHighlights .top_head {
  background-image: url(../../../images/wearables/TopHeadIcon.svg);
}

.WearableHighlights .upper_body {
  background-image: url(../../../images/wearables/UpperBodyIcon.svg);
}

.WearableHighlights .gun {
  background-image: url(../../../images/wearables/GunIcon.svg);
}

.WearableHighlights .pipe {
  background-image: url(../../../images/wearables/PipeIcon.svg);
}

.WearableHighlights .bong {
  background-image: url(../../../images/wearables/BongIcon.svg);
}

.WearableHighlights .cigar {
  background-image: url(../../../images/wearables/CigarIcon.svg);
}

.WearableHighlights .joint {
  background-image: url(../../../images/wearables/JointIcon.svg);
}

.WearableHighlights .beer {
  background-image: url(../../../images/wearables/BeerIcon.svg);
}

.WearableHighlights .balloons {
  background-image: url(../../../images/wearables/PipeIcon.svg);
}

.WearableHighlights .guitar {
  background-image: url(../../../images/wearables/GuitarIcon.svg);
}

.WearableHighlights .gaming {
  background-image: url(../../../images/wearables/GamingIcon.svg);
}

.WearableHighlights .headphones {
  background-image: url(../../../images/wearables/HeadphonesIcon.svg);
}

.WearableHighlights .soccer {
  background-image: url(../../../images/wearables/SoccerIcon.svg);
}

.WearableHighlights .basketball {
  background-image: url(../../../images/wearables/BasketballIcon.svg);
}

.WearableHighlights .golf {
  background-image: url(../../../images/wearables/GolfIcon.svg);
}

.WearableHighlights .snowboard {
  background-image: url(../../../images/wearables/SnowboardIcon.svg);
}

.WearableHighlights .skis {
  background-image: url(../../../images/wearables/SkisIcon.svg);
}

.WearableHighlights .skateboard {
  background-image: url(../../../images/wearables/SkateboardIcon.svg);
}

.WearableHighlights .rollerskates {
  background-image: url(../../../images/wearables/RollerSkatesIcon.svg);
}

.WearableHighlights .weights {
  background-image: url(../../../images/wearables/WeightsIcon.svg);
}

.WearableHighlights .hotdog {
  background-image: url(../../../images/wearables/HotdogIcon.svg);
}

.WearableHighlights .pizza {
  background-image: url(../../../images/wearables/PizzaIcon.svg);
}

.WearableHighlights .ice_cream {
  background-image: url(../../../images/wearables/IceCreamIcon.svg);
}

.WearableHighlights .burger {
  background-image: url(../../../images/wearables/BurgerIcon.svg);
}

.WearableHighlights .taco {
  background-image: url(../../../images/wearables/TacoIcon.svg);
}

.WearableHighlights .dog {
  background-image: url(../../../images/wearables/DogIcon.svg);
}

.WearableHighlights .cat {
  background-image: url(../../../images/wearables/CatIcon.svg);
}

.WearableHighlights .panda {
  background-image: url(../../../images/wearables/PandaIcon.svg);
}

.WearableHighlights .dragon {
  background-image: url(../../../images/wearables/DragonIcon.svg);
}

.WearableHighlights .shark {
  background-image: url(../../../images/wearables/SharkIcon.svg);
}

.WearableHighlights .fish {
  background-image: url(../../../images/wearables/FishIcon.svg);
}

.WearableHighlights .dolphin {
  background-image: url(../../../images/wearables/DolphinIcon.svg);
}

.WearableHighlights .bird {
  background-image: url(../../../images/wearables/BirdIcon.svg);
}
