.AssetImage {
  position: relative;
  height: 100%;
  width: 100%;
}

.AssetImage .pixel {
  display: block;
  width: 100%;
  height: auto;
}

.AssetImage .image-wrapper {
  text-align: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.AssetImage .image {
  margin: auto;
  max-height: 100%;
}

.AssetImage .image-tpunk {
  margin: auto;
  height: 100%;
  max-width: 100%;
  image-rendering: pixelated;
}

.AssetImage .rarity-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.AssetImage .ens-subdomain {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  height: 100%;
  background-image: url('../../images/logo_dark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #37333d;
  color: white;
  flex-direction: column;
}

.AssetImage .ens-subdomain .name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 16px 16px 16px;
}

.AssetImage .ens-subdomain.small .name {
  text-overflow: unset;
  padding: 10px 0px;
  font-size: 60%;
}

.AssetImage .ens-subdomain .monospace {
  font-family: 'Inconsolata', monospace;
  font-size: 17px;
  line-height: 24px;
  margin-top: 12px;
  color: var(--secondary-text);
  padding: 0px 16px 0px 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: lowercase;
}
